import gql from "graphql-tag";

export const SCORE_WRITING = gql`
  mutation scoreText($text: String) {
    scoreText(text: $text)
  }
`;

export default {
  "score.writing": SCORE_WRITING
};
