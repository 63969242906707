import gql from "graphql-tag";
import { USER_ACTIVITY_FIELDS } from "../fragments/user_activity";
export const USER_ACTIVITY_UPDATE = gql`
  mutation update_user_activity_by_pk(
    $pk_columns: user_activity_pk_columns_input!
    $_set: user_activity_set_input
  ) {
    update_user_activity_by_pk(pk_columns: $pk_columns, _set: $_set) {
      ...USER_ACTIVITY_FIELDS
    }
  }
  ${USER_ACTIVITY_FIELDS}
`;
const user_activity_mutations = {
  "user.activity.update": USER_ACTIVITY_UPDATE,
};
export default user_activity_mutations;
