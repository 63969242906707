import gql from "graphql-tag";
import { USER_FIELDS } from "../fragments/user";
export const USER_GET_ONE = gql`
  query user_by_pk($id: uuid!) {
    user_by_pk(id: $id) {
      ...USER_FIELDS
    }
  }
  ${USER_FIELDS}
`;

export const USER_GET_COUNT = gql`
  query users($where: UserWhereInput) {
    users(where: $where) {
      count
    }
  }
`;

export default {
  "user.get.one": USER_GET_ONE,
  "user.get.count": USER_GET_COUNT
};
