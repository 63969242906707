import gql from "graphql-tag";

export const FLUENCYTOOL_GET_MANY = gql`
  query fluencyTools(
    $where: FluencyToolWhereInput
    $withSelect: Boolean
    $orderBy: [FluencyToolWhereInput]
  ) {
    fluencyTools(where: $where, withSelect: $withSelect, orderBy: $orderBy) {
      count
      data {
        id
        textToScore
      }
    }
  }
`;

export const FLUENCYTOOL_GET_ONE = gql`
  query fluencyTool($where: FluencyToolWhereInput, $withSelect: Boolean) {
    fluencyTool(where: $where, withSelect: $withSelect) {
      id
      textToScore
    }
  }
`;

export default {
  "fluency.tool.get.many": FLUENCYTOOL_GET_MANY,
  "fluency.tool.get.one": FLUENCYTOOL_GET_ONE
};
