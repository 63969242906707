import gql from "graphql-tag";

export const IMAGE_CREATE = gql`
  mutation createImage($data: ImageWhereInput) {
    createImage(data: $data) {
      id
      name
      path
      cloudinaryId
      createdAt
    }
  }
`;

export const IMAGE_DELETE = gql`
  mutation deleteImage($where: ImageWhereInput) {
    deleteImage(where: $where) {
      id
      name
      path
      cloudinaryId
      createdAt
    }
  }
`;

export default {
  "image.create": IMAGE_CREATE,
  "image.delete": IMAGE_DELETE
};
