import gql from "graphql-tag";

export const PROGRESSION_GET_ONE = gql`
  query progression_by_pk($id: uuid!) {
    progression_by_pk(id: $id) {
      id
      course {
        id
        title
        image
      }
      level {
        id
        name
        order
        image
        overview
      }
      unit {
        id
      }
      lesson {
        id
      }
      activity {
        id
        name
      }
    }
  }
`;

export const PROGRESSION_GET_MANY = gql`
  query progression_get_many {
    progression_get_many {
      id
      course {
        id
        title
        image
      }
      level {
        id
        name
        order
        image
        overview
      }
      unit {
        id
      }
      lesson {
        id
      }
      activity {
        id
      }
    }
  }
`;

const progression_queries = {
  "progression.get.one": PROGRESSION_GET_ONE,
  "progression.get.many": PROGRESSION_GET_MANY,
};

export default progression_queries
// level => units
// unit => lessons
// lesson => higlighted lesson
