import PropTypes from "prop-types";
import { useQuery } from "@apollo/client";

import queries from "./schemas/queries";

function useQueryWrapper({ event, ...rest }) {
  const { loading, error, data, fetchMore } = useQuery(queries[event], {
    ...rest,
  });

  if (error) return `Error, ${error.message}`;

  return { data, loading, fetchMore };
}

useQueryWrapper.propTypes = {
  event: PropTypes.string.isRequired,
};

export default useQueryWrapper;
