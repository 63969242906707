import gql from "graphql-tag";

export const FILE_UPLOAD_ONE = gql`
  mutation singleUpload($file: Upload!) {
    singleUpload(file: $file)
  }
`;

export default {
  "file.upload.one": FILE_UPLOAD_ONE
};
