import gql from "graphql-tag";

export const SCORE_FLUENCY = gql`
  mutation scoreFluencyTool($text: String, $url: String) {
    scoreFluencyTool(text: $text, url: $url)
  }
`;

export default {
  "score.fluency": SCORE_FLUENCY
};
