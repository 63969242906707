import gql from "graphql-tag";
import { body } from "./fragments/progression";

export const PROGRESSION_UPDATE = gql`
  mutation update_progression_by_pk(
    $_set: progression_set_input
    $pk_columns: progression_pk_columns_input!
  ) {
    update_progression_by_pk(_set: $_set, pk_columns: $pk_columns) {
      id
      course {
        id
        title
        image
      }
      level {
        id
        name
        order
        image
        overview
      }
      unit {
        id
      }
      lesson {
        id
      }
      activity {
        id
      }
    }
  }
`;
export const PROGRESSION_RESET_UNIT = gql`
  mutation resetProgressionUnit($where: ProgressionWhereInput) {
    resetProgressionUnit(where: $where) {
      ${body}
    }
  }
`;

export const PROGRESSION_RESET_LEVEL = gql`
  mutation resetProgressionLevel($where: ProgressionWhereInput) {
    resetProgressionLevel(where: $where) {
      ${body}
    }
  }
`;

export const PROGRESSION_RESET_EXAM = gql`
  mutation resetProgressionExam($where: ProgressionWhereInput, $exam: ExamWhereInput) {
    resetProgressionExam(where: $where, exam: $exam) {
      ${body}
    }
  }
`;

export const PROGRESSION_NEXT = gql`
  mutation goToNextProgression($where: ProgressionWhereInput) {
    goToNextProgression(where: $where) {
      ${body}
    }
  }
`;

export default {
  "progression.update": PROGRESSION_UPDATE,
  "progression.reset.unit": PROGRESSION_RESET_UNIT,
  "progression.reset.level": PROGRESSION_RESET_LEVEL,
  "progression.reset.exam": PROGRESSION_RESET_EXAM,
  "progression.next": PROGRESSION_NEXT,
};
