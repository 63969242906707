import gql from "graphql-tag";

export const QUESTIONOPTION_CREATE = gql`
  mutation createQuestionOption($data: QuestionOptionWhereInput) {
    createQuestionOption(data: $data) {
      id
      label
      order
      rightAnswer
    }
  }
`;

export const QUESTIONOPTION_DELETE = gql`
  mutation deleteQuestionOption($where: QuestionOptionWhereInput) {
    deleteQuestionOption(where: $where) {
      id
      label
      order
      rightAnswer
    }
  }
`;

export default {
  "questionOption.create": QUESTIONOPTION_CREATE,
  "questionOption.delete": QUESTIONOPTION_DELETE
};
