export const quiz = `
quiz {
    id
    title
    type
    questions {
      id
      label
      media{
        id
        path
      }
      order
      question_options {
        id
        label
        order
        rightAnswer
      }
    }
  }
`;

export const fill_blank = `
fill_blank {
    id
    title
    questions {
      id
      label
      correctAnswerId
      order
    }
    question_options {
      id
      label
      order
    }
  }
`;

export const dnd = `
dnd {
    id
    title
    questions {
      id
      label
      correctAnswerId
      media{
        id
        path
      }
      order
    }
    question_options {
      id
      label
      order
    }
  }
`;

export const image = `
image {
  id
  name
  path
  cloudinaryId
}
`;

export const speech = `
fluencyTool {
  id
  title
  sentencesToScore
}
`;

export const text = `
text {
  id
  title
  content
}
`;

export const media = `
media {
  id
  label
  type
  path
}
`;
