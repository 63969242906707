import gql from "graphql-tag";

export const USER_LOGIN = gql`
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      token
      user {
        id
        firstName
        lastName
        email
        enabled
        picture
        confirmed
      }
    }
  }
`;

export default {
  "user.login": USER_LOGIN
};
