import gql from "graphql-tag";

import { BLOCK_FIELDS } from "../fragments/block";

export const BLOCK_GET_MANY = gql`
  query block(
    $limit: Int
    $offset: Int
    $where: block_bool_exp
    $order_by: [block_order_by!]
  ) {
    block(limit: $limit, offset: $offset, where: $where, order_by: $order_by) {
      ...BLOCK_FIELDS
    }
  }
  ${BLOCK_FIELDS}
`;

export const BLOCK_GET_ONE = gql`
  query block_by_pk($id: uuid!) {
    block_by_pk(id: $id) {
      ...BLOCK_FIELDS
    }
  }
  ${BLOCK_FIELDS}
`;

const block_queries = {
  "block.get.one": BLOCK_GET_ONE,
  "block.get.many": BLOCK_GET_MANY,
};

export default block_queries;
