import gql from "graphql-tag";

export const UNIT_START = gql`
  mutation unit_start($unitId: uuid!) {
    unit_start(unitId: $unitId) {
      status
    }
  }
`;

const unit_mutations = {
  "unit.start": UNIT_START,
};

export default unit_mutations;
