import gql from "graphql-tag";

export const USER_UPDATE = gql`
  mutation updateUser($where: UserWhereInput, $data: UserWhereInput) {
    updateUser(where: $where, data: $data) {
      id
      email
      firstName
      lastName
      picture
      lastLogin
      confirmed
    }
  }
`;

export const USER_CHANGE_PASSWORD = gql`
  mutation changePassword($where: UserWhereInput, $data: UserWhereInput) {
    changePassword(where: $where, data: $data) {
      id
    }
  }
`;

export const USER_RESET_PASSWORD = gql`
  mutation resetPassword($where: UserWhereInput) {
    resetPassword(where: $where) {
      id
    }
  }
`;

export default {
  "user.update": USER_UPDATE,
  "user.change.password": USER_CHANGE_PASSWORD,
  "user.password.reset": USER_RESET_PASSWORD
};
