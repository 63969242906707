import { gql } from "@apollo/client";

export const UNIT_FIELDS = gql`
  fragment UNIT_FIELDS on unit {
    id
    name
    order
    overview
    image
    lessons {
      id
      name
      activities {
        id
        name
      }
    }
  }
`;
