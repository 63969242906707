import gql from "graphql-tag";

export const SECTION_CREATE = gql`
  mutation createSection($data: SectionWhereInput) {
    createSection(data: $data) {
      id
      name
      order
    }
  }
`;

export const SECTION_DELETE = gql`
  mutation deleteSection($where: SectionWhereInput) {
    deleteSection(where: $where) {
      id
      name
      order
    }
  }
`;

export default {
  "section.create": SECTION_CREATE,
  "section.delete": SECTION_DELETE
};
