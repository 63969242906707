import useQuery from "./useQuery";
import useMutation from "./useMutation";
import cache from "./cache";

export { useQuery };
export { useMutation };
export { cache };
const client = {
  useQuery,
  useMutation,
  cache,
};
export default client;
