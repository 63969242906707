import { gql } from "@apollo/client";

export const ACTIVITY_LAYOUT_FIELDS = gql`
  fragment ACTIVITY_LAYOUT_FIELDS on activity_layout {
    id
    activityId
    blockId
    size
    order
    isStatic
    type
  }
`;
