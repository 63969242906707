
import { notificationVar } from "services/Client/cache";

export default function useNotification() {
  const error = (props) => {
    notificationVar({
      ...props,
      type: "danger",
      opened: true,
      __typename: "notification",
    });
  };

  const success = (props) => {
    notificationVar({
      ...props,
      opened: true,
      __typename: "notification",
    });
  };

  const warning = (message) => {
    notificationVar({
      message,
      type: "warning",
      opened: true,
      __typename: "notification",
    });
  };

  const notification = {
    error,
    success,
    warning,
  };
  return notification;
}
