import gql from "graphql-tag";

export const QUESTION_CREATE = gql`
  mutation createQuestion($data: QuestionWhereInput) {
    createQuestion(data: $data) {
      id
      label
      order
      createdAt
    }
  }
`;

export const QUESTION_DELETE = gql`
  mutation deleteQuestion($where: QuestionWhereInput) {
    deleteQuestion(where: $where) {
      id
      label
      order
      createdAt
    }
  }
`;

export default {
  "question.create": QUESTION_CREATE,
  "question.delete": QUESTION_DELETE
};
