import { gql } from "@apollo/client";

export const USER_ACTIVITY_FIELDS = gql`
  fragment USER_ACTIVITY_FIELDS on user_activity {
    id
    answers
    infos
    activity {
      id
    }
  }
`;
