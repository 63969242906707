import PropTypes from "prop-types";
import { useMutation } from "@apollo/client";

import mutations from "./schemas/mutations";
import queries from "./schemas/queries";

import { useNotification } from "hooks";

function useMutationWrapper({ event, update = () => null, ...rest }) {
  const notification = useNotification();
  const [mutate, { loading }] = useMutation(mutations[event], {
    update(cache, { data: returnedData }) {
      const updateData = update({ data: returnedData }, cache);
      if (!updateData) return;
      const { event: queryEvent, variables, data } = updateData;
      cache.writeQuery({
        query: queries[queryEvent],
        variables,
        data,
      });
    },
    onError({ graphQLErrors }) {
      for (let err of graphQLErrors) {
        switch (err.extensions.code) {
          case "data-exception":
            notification.error({ message: "Incomplete Data" });
            break;
          case "validation-failed":
            notification.error({ message: "Invalid Data" });
            return;
          case "permission-error":
            notification.error({ message: err.message });
            return;
          case "constraint-violation":
            notification.error({ message: err.message });
            return;
          default:
            return;
        }
      }
    },
    ...rest,
  });

  // if (loading) {
  //   if (event === "file.upload.one") {
  //     client.writeData({
  //       data: {
  //         uploading: true
  //       }
  //     });
  //   }
  // }
  //   if (error) return `Error, ${error.message}`;
  return { mutate, loading };
}

useMutationWrapper.propTypes = {
  event: PropTypes.string.isRequired,
  update: PropTypes.func,
};

export default useMutationWrapper;
