import login from "./login";
import userActivity from "./userActivity";
import file from "./file";
import user from "./user";
import group from "./group";
import course from "./course";
import section from "./section";
import image from "./image";
import text from "./text";
import audio from "./audio";
import fluencyTool from "./fluencyTool";
import activity from "./activity";
import quiz from "./quiz";
import question from "./question";
import questionOption from "./questionOption";
import block from "./block";
import progression from "./progression"
import unit from "./unit"
import writing from './writing'

export default {
  ...login,
  ...user,
  ...group,
  ...audio,
  ...userActivity,
  ...file,
  ...course,
  ...section,
  ...image,
  ...text,
  ...activity,
  ...block,
  ...progression,
  ...unit,
  ...writing,
  ...quiz,
  ...fluencyTool,
  ...question,
  ...questionOption
};
