import gql from "graphql-tag";
import { ACTIVITY_LAYOUT_FIELDS } from "../fragments/activity_layout";

export const ACTIVITY_LAYOUT_GET_MANY = gql`
  query activity_layout(
    $where: activity_layout_bool_exp
    $order_by: [activity_layout_order_by!]
  ) {
    activity_layout(where: $where, order_by: $order_by) {
      ...ACTIVITY_LAYOUT_FIELDS
    }
  }
  ${ACTIVITY_LAYOUT_FIELDS}
`;

const activity_layout_queries = {
  "activity.layout.get.many": ACTIVITY_LAYOUT_GET_MANY,
};

export default activity_layout_queries;
