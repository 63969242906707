import course from "./course";
import client from "./client";
import quiz from "./quiz";
import group from "./group";
import audio from "./audio";
import activity_layout from "./activity_layout";
import userActivity from "./userActivity";
import activity from "./activity";
import user from "./user";
import image from "./image";
import fluencyTool from "./fluencyTool";
import text from "./text";
import block from "./block";
import question from "./question";
import questionOption from "./questionOption";
import unit from "./unit";
import level from "./level";
import progression from "./progression";
import dnd from "./dnd";
export default {
  ...course,
  ...client,
  ...activity_layout,
  ...quiz,
  ...group,
  ...userActivity,
  ...activity,
  ...block,
  ...question,
  ...questionOption,
  ...image,
  ...fluencyTool,
  ...audio,
  ...text,
  ...user,
  ...level,
  ...unit,
  ...progression,
  ...dnd
};
