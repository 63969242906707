import asyncComponent from "shared/helpers/AsyncComponent";
const Home = asyncComponent(() => import("screens/Main"));
const Course = asyncComponent(() => import("screens/Courses"));

var indexRoutes = [
  { path: "/main/:id", name: "Courses", component: Home },
  { path: "/courses", name: "Courses", component: Course, exact: true },
  { path: "/courses/:id", name: "Courses", component: Course, exact: true },
];

export default indexRoutes;
