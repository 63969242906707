import gql from "graphql-tag";

export const GROUP_CREATE = gql`
  mutation createGroup($data: GroupWhereInput) {
    createGroup(data: $data) {
      id
      name
    }
  }
`;

export default {
  "group.create": GROUP_CREATE
};
