import gql from "graphql-tag";
import { UNIT_FIELDS } from "../fragments/unit";
export const UNIT_GET_MANY = gql`
  query unit(
    $limit: Int
    $offset: Int
    $where: unit_bool_exp
    $order_by: [unit_order_by!]
  ) {
    unit(limit: $limit, offset: $offset, where: $where, order_by: $order_by) {
      ...UNIT_FIELDS
    }
  }
  ${UNIT_FIELDS}
`;
const unit_queries = {
  "unit.get.many": UNIT_GET_MANY,
};

export default unit_queries;
