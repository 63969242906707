import Notification from "./notification";
import Paginator from "./paginator";
import Input from "./input";

export { Notification };
export { Paginator };
export { Input };
const shared = { Notification, Paginator, Input };

export default shared;
