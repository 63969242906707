import gql from "graphql-tag";
import { USER_ACTIVITY_FIELDS } from "../fragments/user_activity";
export const USER_ACTIVITY_GET_ONE = gql`
  query user_activity($where: user_activity_bool_exp) {
    user_activity(where: $where) {
      ...USER_ACTIVITY_FIELDS
    }
  }
  ${USER_ACTIVITY_FIELDS}
`;

const user_activity_queries = {
  "user.activity.get.many": USER_ACTIVITY_GET_ONE,
};

export default user_activity_queries;
