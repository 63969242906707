import gql from "graphql-tag";

export const TEXT_GET_ONE = gql`
  query text($where: TextWhereInput, $withSelect: Boolean) {
    text(where: $where, withSelect: $withSelect) {
      id
      title
      content
      createdAt
    }
  }
`;

export const TEXT_GET_MANY = gql`
  query texts(
    $where: TextWhereInput
    $withSelect: Boolean
    $orderBy: [TextWhereInput]
  ) {
    texts(where: $where, withSelect: $withSelect, orderBy: $orderBy) {
      data {
        id
        title
        content
        createdAt
      }
      count
    }
  }
`;

export default {
  "text.get.one": TEXT_GET_ONE,
  "text.get.many": TEXT_GET_MANY
};
