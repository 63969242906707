import { gql } from "@apollo/client";
import { quiz, text, media, fill_blank, dnd } from "./block_fragments";
export const BLOCK_FIELDS = gql`
  fragment BLOCK_FIELDS on block {
    id
    name
    type
    quizId
    fillBlankId
    dndId
    textId
    mediaId
    ${quiz}
    ${fill_blank}
    ${dnd}
    ${text}
    ${media}
  }
`;
