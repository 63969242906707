import { InMemoryCache, makeVar } from "@apollo/client";

export const isLoggedInVar = makeVar(false);
export const tokenVar = makeVar(undefined);
export const userIdVar = makeVar("");
export const isAuthRefreshingVar = makeVar(false);

export const alertVar = makeVar(null);
export const notificationVar = makeVar(null);
export const uploadingVar = makeVar(false);
export const paginatedViewsVar = makeVar([]);
export const searchLikeVar = makeVar("");
export const testViewTitleVar = makeVar("");
export const courseIdVar = makeVar("");
export const currentLevelVar = makeVar("");
export const currentUnitVar = makeVar("");
export const currentLessonVar = makeVar("");
export const currentActivityNameVar = makeVar("");


export const in_memory_cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        token: {
          read() {
            return tokenVar();
          },
        },
        userId: {
          read() {
            return userIdVar();
          },
        },
        isLoggedIn: {
          read() {
            return isLoggedInVar();
          },
        },
        courseId: {
          read() {
            return courseIdVar();
          },
        },
        currentLevel: {
          read() {
            return currentLevelVar();
          },
        },
        currentUnit: {
          read() {
            return currentUnitVar();
          },
        },
        currentLesson: {
          read() {
            return currentLessonVar();
          },
        },
        currentActivityName: {
          read() {
            return currentActivityNameVar();
          },
        },
        paginatedViews: {
          read() {
            return paginatedViewsVar();
          },
        },
        notification: {
          read() {
            return notificationVar();
          },
        },
        alert: {
          read() {
            return alertVar();
          },
        },
        searchLike: {
          read() {
            return searchLikeVar();
          },
        },
        testViewTitle: {
          read() {
            return testViewTitleVar();
          },
        },
        groupPlacementTests: {
          keyArgs: false,
          merge(existing, incoming) {
            // let rules = existing.rules.slice(0);
            // if (incoming.rules)
            //   incoming.rules.forEach((rule, idx) => (rules[skip + idx] = rule));
            return incoming;
          },
        },
        questionOptions: {
          keyArgs: ["where"],
          merge(existing, incoming) {
            // let rules = existing.rules.slice(0);
            // if (incoming.rules)
            //   incoming.rules.forEach((rule, idx) => (rules[skip + idx] = rule));
            return incoming;
          },
        },
        placementTests: {
          merge(existing = [], incoming, { args: { offset } }) {
            console.log(incoming);
            let patients = existing.slice(0);
            incoming.data.forEach(
              (patient, idx) => (patients[offset + idx] = patient)
            );
            return patients;
          },
          read(existing = [], { args }) {
            // If we read the field before any data has been written to the
            // cache, this function will return undefined, which correctly
            // indicates that the field is missing.
            const page =
              existing && existing.slice(args.offset, args.offset + args.limit);
            // If we ask for a page outside the bounds of the existing array,
            // page.length will be 0, and we should return undefined instead of
            // the empty array.
            if (page && page.length > 0) {
              return page;
            }
          },
          keyArgs: false,
        },
        user: {
          merge(existing = [], incoming, { args: { offset } }) {
            let users = existing.slice(0);
            incoming.forEach((user, idx) => (users[offset + idx] = user));
            return users;
          },
          read(existing = [], { args: { offset, limit } }) {
            // If we read the field before any data has been written to the
            // cache, this function will return undefined, which correctly
            // indicates that the field is missing.
            const page = existing && existing.slice(offset, offset + limit);
            // If we ask for a page outside the bounds of the existing array,
            // page.length will be 0, and we should return undefined instead of
            // the empty array.
            if (page && page.length > 0) {
              return page;
            }
          },
          keyArgs: ["where"],
        },
        group: {
          merge(existing = [], incoming, { args: { offset } }) {
            let users = existing.slice(0);
            incoming.forEach((user, idx) => (users[offset + idx] = user));
            return users;
          },
          read(existing = [], { args: { offset, limit } }) {
            // If we read the field before any data has been written to the
            // cache, this function will return undefined, which correctly
            // indicates that the field is missing.
            const page = existing && existing.slice(offset, offset + limit);
            // If we ask for a page outside the bounds of the existing array,
            // page.length will be 0, and we should return undefined instead of
            // the empty array.
            if (page && page.length > 0) {
              return page;
            }
          },
          keyArgs: ["where"],
        },
        placement_test: {
          merge(existing = [], incoming, { args: { offset } }) {
            let users = existing.slice(0);
            incoming.forEach((user, idx) => (users[offset + idx] = user));
            return users;
          },
          read(existing = [], { args: { offset, limit } }) {
            // If we read the field before any data has been written to the
            // cache, this function will return undefined, which correctly
            // indicates that the field is missing.
            const page = existing && existing.slice(offset, offset + limit);
            // If we ask for a page outside the bounds of the existing array,
            // page.length will be 0, and we should return undefined instead of
            // the empty array.
            if (page && page.length > 0) {
              return page;
            }
          },
          keyArgs: ["where"],
        },
        media: {
          merge(existing = [], incoming, { args: { offset } }) {
            let users = existing.slice(0);
            incoming.forEach((user, idx) => (users[offset + idx] = user));
            return users;
          },
          read(existing = [], { args: { offset, limit } }) {
            // If we read the field before any data has been written to the
            // cache, this function will return undefined, which correctly
            // indicates that the field is missing.
            const page = existing && existing.slice(offset, offset + limit);
            // If we ask for a page outside the bounds of the existing array,
            // page.length will be 0, and we should return undefined instead of
            // the empty array.
            if (page && page.length > 0) {
              return page;
            }
          },
          keyArgs: ["where"],
        },
        quiz: {
          merge(existing = [], incoming, { args: { offset } }) {
            let users = existing.slice(0);
            incoming.forEach((user, idx) => (users[offset + idx] = user));
            return users;
          },
          read(existing = [], { args: { offset, limit } }) {
            // If we read the field before any data has been written to the
            // cache, this function will return undefined, which correctly
            // indicates that the field is missing.
            const page = existing && existing.slice(offset, offset + limit);
            // If we ask for a page outside the bounds of the existing array,
            // page.length will be 0, and we should return undefined instead of
            // the empty array.
            if (page && page.length > 0) {
              return page;
            }
          },
          keyArgs: ["where"],
        },
      },
    },
  },
});

const localVariables = {
  courseIdVar,
  currentLevelVar,
  currentUnitVar,
  currentLessonVar,
  currentActivityNameVar,
  notificationVar,
  testViewTitleVar,
  alertVar,
  uploadingVar,
  paginatedViewsVar,
  isLoggedInVar,
  tokenVar,
  searchLikeVar,
  isAuthRefreshingVar,
};

const cache = {
  cache: in_memory_cache,
  ...localVariables,
};

export default cache;
