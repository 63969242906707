import gql from "graphql-tag";

export const LEVEL_GET_ONE = gql`
  query level_by_pk(
    $id: uuid!
  ) {
    level_by_pk(id: $id) {
      id
      name
      overview
      exam {
        id
      }
      units {
        id
        order
        name
        lessons {
          id
          order
          name
          activities {
            id
            order
            name
            examId
          }
        }
      }
    }
  }
`;

export const LEVEL_GET_MANY = gql`
  query levels(
    $where: LevelWhereInput
    $withSelect: Boolean
    $orderBy: [LevelWhereInput]
  ) {
    levels(where: $where, withSelect: $withSelect, orderBy: $orderBy) {
      count
      data {
        id
        name
        units {
          id
          name
        }
      }
    }
  }
`;

export default {
  "level.get.one": LEVEL_GET_ONE,
  "level.get.many": LEVEL_GET_MANY
};
